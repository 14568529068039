module.exports = {
  bg: {
    texture: 'room',
    x: 0.5,
    y: 0.5,
    z: 1,
    scale: 1.03,
    str: 1.6,
    ox: 0.5,
    oy: 0.492,
  },
  room: {
    x: 0.5,
    y: 0.5,
    z: 2,
    str: 1,
    ox: 0.5,
    oy: 0.5,
  },
  chef: {
    x: 0.267,
    y: 0.4,
    z: 3,
    str: 1,
    ox: 0.5,
    oy: 0.8,
    text: 'Baking the Cake',
    project: 'bakingrelay',
    font: 32,
    dir: 'left',
    audio: 'bakingrelay',
  },
  carpet: {
    x: 0.5,
    y: 0.83,
    z: 5,
    str: 0.9,
    ox: 0.5,
    oy: 0.5,
  },
  balloons: {
    x: 0.52,
    y: 0.16,
    z: 4,
    str: 1.4,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  painting: {
    x: 0.35,
    y: 0.45,
    z: 8,
    str: 1.3,
    ox: 0.5,
    oy: 0.65,
    text: 'Drawing Board',
    project: 'mural',
    dir: 'top',
    audio: 'mural',
  },
  gifts: {
    x: 0.67,
    y: 0.49,
    z: 6,
    str: 1.2,
    ox: 0.5,
    oy: 0.5,
    text: 'Message Cards',
    project: 'messages',
    font: 40,
    dir: 'top',
    audio: 'messages',
  },
  banner: {
    x: 0.5,
    y: 0.08,
    z: 7,
    scale: 1.1,
    str: 1,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  roomie: {
    x: 0.45,
    y: 0.42,
    z: 9,
    str: 1,
    ox: 0.5,
    oy: 0.5,
    dir: 'right',
  },
  reimu: {
    x: 0.18,
    y: 0.24,
    z: 100,
    str: 1.2,
    ox: 0.5,
    oy: 0.7,
    text: 'Quests',
    project: 'quests',
    font: 32,
    audio: 'reimu',
    volume: 0.1,
  },
  couch: {
    x: 0.14,
    y: 0.68,
    z: 101,
    str: 1.1,
    ox: 0.5,
    oy: 0.5,
    dir: 'top',
  },
  nina: {
    x: 0.2,
    y: 0.73,
    z: 102,
    scale: 1.2,
    str: 0.95,
    ox: 0.5,
    oy: 0.7,
    text: 'God Sees All',
    project: 'gsacover',
    font: 35,
    dir: 'right',
    audio: 'gsacover',
    volume: 0.2,
  },
  playbtn: {
    x: 0.85,
    y: 0.13,
    z: 99.8,
    str: 0.9,
    ox: 0.5,
    oy: 0.5,
    text: 'Toggle Sounds',
    project: null,
    font: 35,
    dir: 'right',
  },
  rack: {
    x: 0.915,
    y: 0.6,
    z: 100,
    str: 0.7,
    ox: 0.5,
    oy: 0.5,
    dir: 'right',
  },
  radio: {
    x: 0.949,
    y: 0.35,
    z: 101,
    str: 0.3,
    ox: 0.3,
    oy: 0.5,
    text: 'Radioshow',
    project: 'aloucast',
    font: 33,
    dir: 'top',
  },
  tv: {
    x: 0.96,
    y: 0.61,
    z: 101.8,
    str: 0.3,
    ox: 0.5,
    oy: 0.5,
    text: 'Watch TV',
    project: 'rickroll',
    font: 37,
    dir: 'right',
    audio: 'rickroll',
  },
  enna: {
    x: 0.5,
    y: 0.71,
    z: 108,
    str: 0,
    dir: 'bottom',
  },
  table: {
    x: 0.5,
    y: 0.88,
    z: 110,
    scale: 1,
    str: -0.3,
    ox: 0.5,
    oy: 0.5,
    dir: 'bottom',
  },
  aptable: {
    x: 0.4735,
    y: 0.828,
    z: 111,
    scale: 1,
    str: -0.3,
    ox: 0.5,
    oy: 0.5,
    dir: 'bottom',
  },
  cake: {
    x: 0.5,
    y: 0.83,
    z: 111,
    str: -0.45,
    ox: 0.5,
    oy: 0.5,
    text: 'Blow the Candle!',
    project: 'cake',
    font: 30,
    dir: 'top',
  },
  millie: {
    x: 0.69,
    y: 0.93,
    z: 112,
    str: -0.5,
    ox: 0.5,
    oy: 0.8,
    text: 'Toggle Confetti',
    project: null,
    font: 30,
    dir: 'bottom',
    audio: 'millie',
    volume: 1,
  },
};
