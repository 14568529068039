module.exports = [
  {
    sprite: 'dancing',
    x: 0.85,
    y: 0.86,
    z: 101.1,
    scale: 2.4,
    str: 1,
    ox: 0.7,
    oy: 0.5,
    text: 'Birthday Wishes',
    project: 'bdayvoices',
    font: 40,
    audio: 'bdayvoices',
  },
  {
    sprite: 'geddan',
    x: 0.17,
    y: 0.91,
    z: 103.1,
    scale: 1.1,
    str: 1,
    start: 100, // ms
  },
  {
    sprite: 'geddan',
    x: 0.22,
    y: 0.88,
    z: 103,
    scale: 1,
    str: 1,
    flip: true,
  },
  {
    sprite: 'specialist',
    x: 0.78,
    y: 0.58,
    z: 101.3,
    scale: 1.9,
    str: 0.9,
    text: 'Credits',
    project: 'credits',
    font: 34,
  },
  {
    sprite: 'normaldance',
    x: 0.76,
    y: 0.66,
    z: 101.4,
    scale: 0.55,
    str: 0.8,
  },
  {
    sprite: 'dancing',
    x: 0.45,
    y: 0.66,
    z: 80,
    scale: 2.1,
    str: 1.1,
    flip: true,
    start: 100, // ms
  },
  {
    sprite: 'doublepeeps',
    x: 0.65,
    y: 0.68,
    z: 81,
    scale: 0.75,
    str: 1.2,
  },
  {
    sprite: 'pwoot',
    x: 0.31,
    y: 0.64,
    z: 100.1,
    scale: 1,
    str: 1.2,
    flip: true,
    ox: 0.5,
    oy: 0.6,
  },
  {
    sprite: 'pwoot',
    x: 0.83,
    y: 0.72,
    z: 101.4,
    scale: 0.85,
    str: 0.6,
    start: 50,
  },
  {
    sprite: 'alousus',
    x: 0.1,
    y: 0.94,
    z: 103,
    scale: 0.5,
    str: 0.3,
  },
  {
    sprite: 'normaldance',
    x: 0.05,
    y: 0.89,
    z: 103,
    scale: 0.6,
    str: 0.3,
    text: 'Dance Dance',
    project: 'bdaydance',
    font: 30,
    flip: true,
    audio: 'bdaydance',
    volume: 0.2,
  },
  {
    sprite: 'dazed',
    x: 0.53,
    y: 0.53,
    z: 101,
    scale: 0.55,
    str: 0.2,
  },
  {
    sprite: 'dazed',
    x: 0.26,
    y: 0.95,
    z: 103,
    scale: 0.7,
    str: 0.5,
    flip: true,
  },
  {
    sprite: 'alousus',
    x: 0.4,
    y: 0.56,
    z: 103,
    scale: 0.35,
    str: 0.6,
    flip: true,
    start: 250,
  },
];
